var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Home"}},[_c('div',{staticStyle:{"width":"100%"}}),(false)?_c('div',{staticClass:"rooms"},_vm._l((_vm.Headlines),function(item,index){return _c('div',{key:index,staticClass:"rooms_item",on:{"click":function($event){return _vm.toRoom(item.id)}}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":item.user.avatar,"alt":""}})]),_c('div',{staticClass:"room_data"},[_c('div',{staticClass:"room_title"},[_vm._v(_vm._s(_vm._f("StrIntercept")(item.name)))]),_c('div',{staticClass:"room_price"},[_vm._v(" 总价值:"),_c('span',[_c('img',{attrs:{"src":require("@/assets/images/public/Gold.png"),"alt":""}}),_vm._v(_vm._s(item.award_bean))])])])]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"pic",style:({
            'background-image': 'url(' + item.box_records[0].lv_bgImage + ')',
          })},[_c('img',{attrs:{"src":item.box_records[0].cover,"alt":""}})])]),_c('div',{staticClass:"countDown"},[_c('van-count-down',{attrs:{"time":new Date(item.end_time).getTime() - new Date().getTime(),"format":"DD 天 HH 时 mm 分"},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [_c('span',{staticClass:"block",domProps:{"textContent":_vm._s(
                timeData.days < 10 ? '0' + timeData.days : timeData.days
              )}}),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block",domProps:{"textContent":_vm._s(
                timeData.hours < 10 ? '0' + timeData.hours : timeData.hours
              )}}),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block",domProps:{"textContent":_vm._s(
                timeData.minutes < 10
                  ? '0' + timeData.minutes
                  : timeData.minutes
              )}})]}}],null,true)})],1)])}),0):_vm._e(),_vm._l((_vm.HotList),function(box,index){return _c('div',{key:index + 'a',staticStyle:{"margin-bottom":"0.5rem"}},[_c('div',{staticClass:"Title"},[_c('p',[_c('i',[_vm._v(_vm._s(box.name))])])]),_c('div',{staticClass:"Blind_boxS"},_vm._l((box.box),function(item,index){return _c('div',{key:index,staticClass:"Blind_item",on:{"click":function($event){return _vm.ToOpen(item.id)}}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"Blind_name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"pic_w"},[_c('img',{attrs:{"src":item.cover,"alt":""}})]),_c('div',{staticClass:"pic_m"},[_c('img',{attrs:{"src":item.weapon_cover,"alt":""}})])]),_c('div',{staticClass:"Blind_btn"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/public/Gold.png"),"alt":""}}),_vm._v(_vm._s(item.bean))])])])}),0)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }